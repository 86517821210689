import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Budget from "@/packages/cassandra-budget/components/BudgetList.vue";

export default [
  {
    path: "/budget",
    component: AppDashboard,
    children: [
      {
        path: "",
        name: "Budget",
        components: {
          view: Budget,
        },
      },
    ],
  },
];
