export default {
  getBudgets: (params) => `hrmis/budget?${formatParams(params)}`,
  getGeneralLedgers: `hrmis/budget/get-general-ledgers`,
  getDonors: `hrmis/budget/get-donors`,
  getActivities: `hrmis/budget/get-activities`,
  getBudgetName: `hrmis/budget/get-budget-name`,
};

function formatParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
