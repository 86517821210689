import store from "./store";
import router from "./router";
import links from "./links";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) {
      options.router.addRoute(...router);
    }
    if (options.store) {
      options.store.registerModule("budget", store);

      /* register side links */
      options.store.commit("Dashboard/ADD_LINK", links);
    }
  },
};
