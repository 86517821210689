export default {
  order: 10,
  name: "Budgets",
  icon: "account_balance",
  links: [
    {
      name: "Budgets",
      icon: "mdi-cash-fast",
      to: "/budget",
      enabled: true,
    },
  ],
  hasSideLinks: true,
  enabled: true,
};
