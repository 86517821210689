import call from "@kinetics254/cassandra-base/service/http";
import budgetConstants from "./budget";

export default {
  namespaced: true,
  state: {
    budgetList: [],
    ledgers: [],
    donors: [],
    activities: [],
    budgetName: "",
    dimensions: [],
    speedKeys: [],
    funds: [],
    currencies: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    budgetGetters: (state) => (val) => state[val],
  },
  actions: {
    getBudgetList: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      console.log("form", payload);
      call("get", budgetConstants.getBudgets(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "budgetList",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getGeneralLedgers: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", budgetConstants.getGeneralLedgers)
        .then((res) => {
          commit("MUTATE", { state: "ledgers", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getDonors: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", budgetConstants.getDonors)
        .then((res) => {
          commit("MUTATE", { state: "donors", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getActivities: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", budgetConstants.getActivities)
        .then((res) => {
          commit("MUTATE", { state: "activities", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getBudgetName: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", budgetConstants.getBudgetName)
        .then((res) => {
          commit("MUTATE", { state: "budgetName", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
